import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { assign, capitalize, omit, pickBy, reduce } from 'lodash';
import { Button, LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import { updatePRO } from '../state/pro-forms';
import { browsePainLocationsIfNeeded } from '../state/app-data';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import { colors, mainDashboard } from '../lib/styles';
import Continue from '../components/continue';
import Layout from '../layouts/common';
import { logEvent } from '../lib/amplitude';
import Page from './page';
import PainBody from '../components/pain-body';
import BodyPainModal from '../components/body-pain-modal';

const baseStyles = {
  appBar: {
    zIndex: 1001,
  },
  backButton: {
    left: '-1.25rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  bottomBtnMargin: {
    marginBottom: '7.5vw',
  },
  continueButton: {
    background: colors.secondaryColor,
  },
  painBodyBtnContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  painBody: {
    minWidth: '85%',
  },
  painBodyAndCirculars: {
    display: 'flex',
    marginBottom: '45px',
    marginLeft: '10px',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    alignItems: 'center',
    color: colors.black,
    display: 'flex',
    fontSize: '2.625rem',
  },
  height: {
    height: '750px',
  },
  helpButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '1.75rem',
    textTransform: 'none',
    color: colors.greyText,
    width: 'max-content',
  },
  message: {
    color: colors.black,
    fontSize: '1.5rem',
  },
};

function calculateDistance(click, point) {
  return ((click.x - point.x) ** 2) + ((click.y - point.y) ** 2);
}

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

class BodyPainPro2 extends Page {
  constructor(props) {
    super(props);

    const pain = props.pain_hx_id ? { ...props.painHx } : {};

    this.state = {
      currentBody: 'BACK',
      maxPoints: 1,
      modalOpen: false,
      pain,
      points: [],
      showConfirmButton: false,
      unconfirmedPoint: null,
    };

    this.cancelPoint = this.cancelPoint.bind(this);
    this.clearPoints = this.clearPoints.bind(this);
    this.confirmPoint = this.confirmPoint.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.painMoves = this.painMoves.bind(this);
    this.painStationary = this.painStationary.bind(this);
    this.updateCurrentBody = this.updateCurrentBody.bind(this);
  }

  componentWillMount() {
    const {
      browsePainLocationsIfNeeded,
    } = this.props;

    browsePainLocationsIfNeeded();
  }

  componentDidMount() {
    scrollToTop();
    const {
      bodyPain,
      painLocations,
    } = this.props;

    if (bodyPain && bodyPain.length > 1) {
      this.setState(bodyPain[1] = undefined);
      this.setState(bodyPain[2] = undefined);
      this.setState(bodyPain[3] = undefined);
      this.setState(bodyPain[4] = undefined);
    }

    const locations = bodyPain[this.getTrackIndex()];

    if (locations) {
      const painLocationsLength = locations.length;
      const points = [];

      locations.forEach((painLocation) => {
        const painPoint = painLocations[painLocation.id];
        points.push(painPoint);
      });

      this.setState({
        maxPoints: painLocationsLength,
        moveDecision: true,
        points,
      });
    }
  }

  cancelPoint() {
    this.setState({
      unconfirmedPoint: null,
    });
  }

  clearPoints() {
    this.setState({
      points: [],
      moveDecision: false,
      maxPoints: 1,
    });
    scrollToTop();
  }

  confirmPoint() {
    logEvent('Confirm Body Pain - Yes');
    let { unconfirmedPoint } = this.state;

    unconfirmedPoint = { ...unconfirmedPoint, confirmed: true };

    this.setState({
      points: this.state.points.concat(unconfirmedPoint),
      showConfirmButton: false,
      unconfirmedPoint: null,
    });
    scrollToTop();
  }

  painMoves() {
    let { points } = this.state;

    points = points.map(point => omit(point, ['painStart']));

    logEvent('Pain Move - Yes');
    this.setState({
      moveDecision: true,
      maxPoints: 2,
      points,
    });
  }

  painStationary() {
    this.setState({
      moveDecision: true,
      maxPoints: 1,
    });
  }

  handleBodyClick(x, y, body) {
    const { maxPoints, unconfirmedPoint, points } = this.state;
    const { painLocations } = this.props;
    if (unconfirmedPoint || points.length >= maxPoints) {
      return;
    }

    const validLocations = pickBy(painLocations, l => l.body_location === body);
    const click = { x, y };

    let closestPoint = reduce(validLocations, (result, value) => {
      const distance = calculateDistance(click, value);
      if (distance < result.distance) {
        return {
          distance,
          ...value,
        };
      }

      return result;
    }, { distance: 99999999 });

    closestPoint = { ...closestPoint, painStart: true };

    this.setState({
      showConfirmButton: true,
      unconfirmedPoint: closestPoint,
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleContinue() {
    const { points } = this.state;
    const locations = points.map((p) => { return { id: p.id, name: p.name }; });
    const pain_locations = points.map((p) => { return p.id; });
    points.length = 0;
    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: { locations, pain_locations } });
    this.clearPoints();
    this.forwardWithQuery(this.props.location.query);
  }

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  updateCurrentBody(currentBody) {
    this.setState({ currentBody });
  }

  render() {
    let clearButton = null;
    let confirmButton = null;
    let continueButton = null;
    const {
      currentBody,
      maxPoints,
      modalOpen,
      moveDecision,
      points,
      showConfirmButton,
      unconfirmedPoint,
    } = this.state;
    let visiblePoints = points;
    let message = (
      <Fragment>
        Tap on the body image to select the location of your pain <br />
        Tap the <span style={{ color: 'red' }}>&lt; &gt;</span> to rotate the image to the side or front of the body
      </Fragment>
    );
    if (!unconfirmedPoint && points.length && points.length < 2) {
      message = (
        <Fragment>
          Tap on the body image to select the location to where your pain travels<br />
          Tap the <span style={{ color: 'red' }}>&lt; &gt;</span> to rotate the image to the side or front of the body
        </Fragment>
      );
      clearButton = null;
    }

    if (unconfirmedPoint) {
      message = (
        <Fragment>
          Tap the Confirm button below to confirm this location <br />
          or <br />
          Tap the <span>&#9746;</span> to erase the incorrect selection and choose a different location.
        </Fragment>
      );
      visiblePoints = points.concat(unconfirmedPoint);
      confirmButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.confirmPoint} text="Confirm" />;
    }

    if (points.length === 1 && !moveDecision) {
      message = 'Does this pain Move?';
      clearButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.painMoves} text="Yes - this pain moves" />;
      continueButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.handleContinue} text="No - This pain does not move" />;
    } else
    if (points.length >= maxPoints) {
      message = 'Is this correct?';
      clearButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.clearPoints} text="Start Over" />;
      continueButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.handleContinue} />;
    }

    return (
      <div onLoad={() => this.clearPoints()}>
        <Layout>
          <section style={mainDashboard.container}>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={`View ${capitalize(currentBody)}`}
              rightNode={
                showConfirmButton
                  ? (
                    <Button
                      onClick={this.confirmPoint}
                      style={baseStyles.helpButton}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      onClick={this.handleOpenModal}
                      style={baseStyles.helpButton}
                    >
                      Help ?
                    </Button>
                  )
              }
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            />
            <div style={baseStyles.painBody}>
              <p style={baseStyles.message}>{message}</p>
              <div>
                <PainBody
                  onBodyClick={this.handleBodyClick}
                  onClickCancel={this.cancelPoint}
                  selectedLocations={visiblePoints}
                  updateCurrentBody={this.updateCurrentBody}
                />
              </div>
              <div style={baseStyles.painBodyBtnContainer}>
                {confirmButton}
                {continueButton}
                {clearButton}
              </div>
            </div>
          </section>
        </Layout>
        <BodyPainModal
          modalOpen={modalOpen}
          onCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: { bodyPain },
    appData: { painLocations },
    user,
  } = state;

  return {
    user,
    bodyPain,
    painLocations,
  };
}

export default connect(mapStateToProps, {
  updatePRO,
  browsePainLocationsIfNeeded,
  fetchUserReport,
  updateAvatarUrl,
})(BodyPainPro2);
